<template>
  <div class="bg-gray-100">
    <div class="absolute p-2 w-full shadow-md">
      <a :href="websiteUrl" target="_blank">
        <img class="h-7 sm:h-10 " alt="DGR logo" src="../assets/navbar-logo.png">
      </a>
    </div>
    <div class="flex justify-center items-center h-screen w-full">
      <div class="flex flex-col justify-center items-center w-2/3 max-w-40">
        <a :href="websiteUrl" target="_blank">
          <img class="h-10 sm:h-12" alt="DGR logo" src="../assets/login-logo.png">
        </a>
        <h1 class="text-4xl text-center mt-4">Log in</h1>
        <div class="info-bar">
          <Snackbar type="error" title="The username or password is incorrect" v-if="isIncorrect"/>
          <Loader isActive="show" v-if="isLoading"/>
        </div>

        <form class="m-5 bg-white rounded-lg p-7 w-full flex flex-col shadow-md">
          <label v-bind:class="{ 'text-red-500': userNameIsReauired }" class="text-xs font-bold" >Email address</label>
          <input v-bind:class="{ 'border-red-500': userNameIsReauired }" class="w-full h-10 border rounded p-2 border-gray-200 mt-1 mb-3 text-sm focus:outline-none focus:ring-2 focus:ring-primary" type="text" v-model="userName">
          <label v-bind:class="{ 'text-red-500': passwordIsReauired }" class="text-xs font-bold">Password</label>
          <input v-bind:class="{ 'border-red-500': passwordIsReauired }" class="w-full h-10 border rounded p-2 border-gray-200 mt-1 text-sm focus:outline-none focus:ring-2 focus:ring-primary" type="password" v-model="password">
          <div class="flex justify-between items-center mt-3 relative">
            <input class="border border-gray-100" type="checkbox">
            <label class="absolute left-6 text-sm">Remember me</label>
            <button class="text-primary underline text-sm focus:outline-none">Forgot your password?</button>
          </div>
          <button class="bg-primary text-white w-2/4 rounded-3xl py-3 mt-5 mx-auto text-base border border-primary focus:outline-none hover:bg-white hover:text-primary hover:shadow-lg" @click="signIn">Log in</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { ref , computed} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import TokenService from '@/services/storage'
import httpClient from '@/services/httpClient'


export default ({
  name: 'Login',
  props: {
    message: {
      type: String,
      required: false,
    },
  },
  created(){
    const currentToken = TokenService.getToken();
    const router = useRouter()
    if(currentToken){
      router.push({ name: 'Orders'})
    }else{
      window.location.href = httpClient.website('/wp-login.php?action=logout');
      return
    }
  },
  setup() {

    

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const userName = ref("")
    const password = ref("")

    const isRequired  = ref(false)
    const isIncorrect = ref(false)
    const isLoading   = ref(false)
    const userNameIsReauired = computed(() => {
      return isRequired.value && userName.value.length <= 0
    })

    const passwordIsReauired = computed(() => {
      return isRequired.value && password.value.length <= 0
    })

    const websiteUrl = computed(() => {
      return httpClient.website('');
    })


    async function signIn(e){
      e.preventDefault()
      isIncorrect.value = false
      isLoading.value = true
      console.log( route.query.returnUrl );
      if(userName.value && password.value){
        let results = await store.dispatch('AuthModule/login',{username:userName.value,password:password.value})
        if(!results){
            isIncorrect.value = true
            isLoading.value = false
            return false
        }else{
            isLoading.value = false
            if(route.query.returnUrl && route.query.returnUrl != "/"){
              //router.push(route.query.returnUrl)
              router.push({ name: 'Orders'})
            }else{
              router.push({ name: 'Orders'})
            }
            return true
        }
      }else{
        isLoading.value = false
        isRequired.value = true
      }
    }

    return {
      signIn,
      userName,
      password,
      userNameIsReauired,
      passwordIsReauired,
      isIncorrect,
      isLoading,
      websiteUrl
    }
  },
});

</script>

<style lang="scss" scoped>
.info-bar {
      position: relative;
    width: 100%;
    height: 50px;
    .loader-container {
      top: 20px;
    }
}
</style>
